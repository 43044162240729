'use strict';

import $ from 'jquery';

let Login = {};

$(function() {
    Login.init();
});

Login = {
    error: '',

    init: function() {
        $(document).on('click', '#btnEnter', () => {
            if (this.check()) {
                this.send();
            } else {
                this.alert(this.error);
            }
        });
    },

    send: function() {
        let _this = this;
        let login = $('[name="email"]').val();
        let password = $('[name="password"]').val();
        let t = $('[name="t"]').val();

        $.ajax({
            method: 'POST',
            url: '/login',
            data: { login: login, password: password, t: t },
            beforeSend: function() {
                _this.disable(true);
            },
            error: function(x, t) {
                _this.alert('Ошибка авторизации. Код ошибки: ' + t);
                _this.disable(false);
            },
            success: function(result) {
                if (result['success']) {
                    if(result['_tt']) {
                        localStorage.setItem('_tt', result['_tt']);
                    }
                    window.location.reload();
                } else if (result['error']) {
                    _this.alert('Ошибка: ' + result['error']);
                    _this.disable(false);
                } else {
                    _this.alert('Ошибка авторизации');
                    _this.disable(false);
                }
            }
        });
    },

    check: function() {
        let email = $('[name="email"]').val().trim();
        let password = $('[name="password"]').val().trim();

        if (email == '') {
            this.error = 'Заполните поле e-mail';
            return false;
        } else if (password == '') {
            this.error = 'Заполните поле пароль';
            return false;
        } else {
            return true;
        }
    },

    alert: function(msg) {
        if (msg) alert(msg);
    },

    disable: function(s) {
        if (s) {
            if (!$('.overlay').is(':visible')) {
                $('.login-box .login-box-body').after('<div class="overlay"><i class="spinner"></i></div>');
            }
        } else {
            $('.overlay').remove();
        }
    }
}